<template>
  <div class="item-wrapper" v-if="item">
    <div class="top">
      <div v-if="tag" class="tag">
        <span>{{ $t(modifiedTag) }}</span>
      </div>
      <div class="title" @click="goToSliderItem(item)">{{ $t(item.title) }}</div>
      <div class="desc" @click="goToSliderItem(item)" v-html="$t(item.description)"></div>
    </div>
    <div class="bottom">
      <div class="buy">
        <button class="button" @click="getPack">
          <span>{{ $t('Buy pack') }}</span>
        </button>
        <div class="price title" v-if="activePack">
          <span>{{ $t(activePack.price) }} <span class="currency">{{ $t(currency) }}</span></span>
        </div>
      </div>
      <div class="preview" @click="goToSliderItem(item)" v-if="lazy">
        <img :data-lazy="$t(imgDomain + item.image_url)" class="img"/>
      </div>
      <div class="preview" @click="goToSliderItem(item)" v-else>
        <img :src="$t(imgDomain + item.image_url)" class="img"/>
      </div>
    </div>
  </div>
</template>


<script>

export default {
	name: 'ProductCard',
	props: ['item', 'sliderIsClickble', 'tag', 'currency', 'models', 'activePack', 'isAuth', 'lazy'],
	data: function() {
		return {
			imgDomain: ''
		}
	},
	watch: {
	},
	computed: {
		modifiedTag: function() {
			if (this.isNumber(this.tag)) {
				let array = this.models.find(item => item.id === this.tag);
				let newtag = array['title']
				return newtag;
			} else {
				return this.tag
			}
		}
	},
	mounted() {
		this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;
	},
	methods: {
		openReadyModal() {
			this.$emit('openReadyModal');
		},
		isNumber(variable) {
			return Number.isFinite(variable) && typeof variable === "number";
		},
		goToSliderItem(item) {
			let self = this;
			setTimeout(()=> {
				if (self.sliderIsClickble == true) {
					self.$emit('goToProduct', item);
				}
			}, 200)
		},
		getPack() {
			if (this.isAuth) {
				this.openReadyModal();
			} else {
				this.openSignInModal();
			}
		},
		openSignInModal() {
			this.$emit('openSignInModal');
		}
	},
}
</script>
