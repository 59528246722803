<template>
  <div class="menu">
    <ul class="nav">
      <template v-for="(item, i) in $parent.nav">
        <li class="nav__item" :key="i" v-if="item.link != '/privacy' && item.link != '/terms'">
          <router-link :to="item.link">
            <span v-if="item.title == 'New Arrivals'">{{ $t('New') }}</span>
            <template v-if="item.title == 'New Arrivals'">
              {{ $t('Arrivals') }}
            </template>
            <template v-else>
              {{ item.title != 'Sale' ? item.title : '' }}
            </template>
            <span class="red" v-if="item.title == 'Sale'">{{ $t('Sale') }}</span>
          </router-link>
        </li>
      </template>
    </ul>
  </div>
</template>

<script>
/*import Vue from 'vue';
var VueScrollTo = require('vue-scrollto');
Vue.use(VueScrollTo)*/
export default {
	name: 'Menu',
	props: [],
	data: function() {
		return {
			
		}
	},
	methods: {
		
	},
}
</script>
