<template>
  <div class="modal contact-modal">
    <div class="overlay"  @click="$emit('closeContactModal')"></div>
    <div class="wrapper">
      <img class="close" @click="$emit('closeContactModal')" src="./../assets/close.svg"/>
      <div class="container">
        <div>
          <div class="form-wrapper">
            <div class="form-fields">
              <div class="title">{{$t('Support Center')}}</div>
              <div class="form-fields-wrapper">
                <label>
                  <input type="text" :placeholder="$t('Name')" v-model="name"/>
                </label>
                <label>
                  <input type="text" :placeholder="$t('Surname')" v-model="surname"/>
                </label>
                <label>
                  <input type="email" :placeholder="$t('E-mail address')" v-model="email"/>
                </label>
                <label>
                  <input type="tel" :placeholder="$t('Phone')" v-model="phone"/>
                </label>
                <label class="full">
                  <input type="text" :placeholder="$t('Topic')" v-model="topic"/>
                </label>
                <label class="full">
                  <textarea :placeholder="$t('Message')" v-model="message"></textarea>
                </label>
                <button @click="submitForm" class="button">
                  <span>{{$t('Send')}}</span>
                </button>
                <transition name="slide">
                  <div v-if="$parent.error" class="error-desc desc red">{{$parent.error}}</div>
                </transition>
                <transition name="slide">
                  <div v-if="$parent.successMessage" class="error-desc desc green">{{$parent.successMessage}}</div>
                </transition>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>

export default {
  name: 'ContactModal',
  props: [],
  components: {
  },
  data: function() {
    return {
      name: '',
      surname: '',
      email: '',
      phone: '',
      topic: '',
      message: ''
    }
  },
  methods: {
    submitForm() {
      let data = {
        "name": this.name,
        "surname": this.surname,
        "email": this.email,
        "phone": this.phone,
        "topic": this.topic,
        "message": this.message
      }
      this.$emit('contactSubmit', data)
    }
  }
}
</script>