<template>
  <div class="modal topup-modal">
    <div class="overlay"  @click="$parent.closeTopUpModal"></div>
    <div class="wrapper">
      <img class="close" @click="$parent.closeTopUpModal" src="./../assets/close.svg"/>
      <div class="container">
        <div>
          <div class="form-wrapper">
            <div class="form-fields" v-if="amountStep">
              <div class="title">{{ $t('Enter amount') }}</div>
              <div class="form-fields-wrapper enter-amount">
                <label>
                  <input type="number" :placeholder="$t('Amount')" v-model="amount"/>
                </label>
                <button @click="nextStep" class="button">
                  <span>{{ $t('Next') }}</span>
                </button>
              </div>
            </div>
            <div class="form-fields" v-if="!amountStep">
              <div class="title">{{ $t('Enter contact information') }}</div>
              <div class="form-fields-wrapper">
                <label>
                  <input type="text" :placeholder="$t('Name')" v-model="name"/>
                </label>
                <label>
                  <input type="text" :placeholder="$t('Surname')" v-model="surname"/>
                </label>
                <label>
                  <input type="email" :placeholder="$t('E-mail address')" v-model="email"/>
                </label>
                <label>
                  <input type="tel" :placeholder="$t('Phone')" v-model="phone"/>
                </label>
                <label>
                  <select v-model="country">
                    <option disabled selected value="">Country</option>
                    <option v-for="(item, i) in countryOptions" :value="item" :key="i">{{ item.title }}</option>
                  </select>
                </label>
                <label>
                  <input type="text" :placeholder="$t('City')" v-model="city"/>
                </label>
                <label>
                  <input type="text" :placeholder="$t('Address')" v-model="address"/>
                </label>
                <label>
                  <input type="text" :placeholder="$t('Post Code')" v-model="postCode"/>
                </label>
                <div class="cta-container">
                  <div class="chekbox-container">
                    <label class="chekbox-label">
                      <div class="chekbox-label-wrapper">
                        <input type="checkbox" name="terms" v-model="terms"/>
                        <div class="checkbox"></div>
                        <span class="title">{{ $t('I agree with') }} </span>
                        <a @click="goToPrivacy" class="title"> {{ $t('privacy policy') }}</a>
                        <span class="title">{{ $t('and') }} </span>
                        <a @click="goToTerms" class="title"> {{ $t('terms and conditions') }}</a>
                      </div>
                    </label>
                  </div>
                  <div class="total">
                    <div class="title price">{{ $t('Top Up') }}: {{ amount }} {{ $parent.currency }}</div>
                  </div>
                  <button :class="['button', {'disabled': !requiredFieldsAreFilled}]" @click="submit">
                    <span>{{ $t('Top up') }}</span>
                  </button>
                </div>
                <transition name="slide">
                  <div v-if="$parent.error" class="error-desc desc red">{{ $t($parent.error) }}</div>
                </transition>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  name: 'TopUpModal',
  props: [],
  components: {
  },
  data: function() {
    return {
      amount: '',
      amountStep: true,
      email: '',
      name: '',
      surname: '',
      phone: '',
      country: '',
      countryId: '',
      city: '',
      address: '',
      postCode: '',
      terms: false,
      countryOptions: [],
      countryOptionsId: [],
    }
  },
  mounted() {
    this.getCounties();
    this.$http.get(process.env.VUE_APP_API + 'user/profile')
    .then((res) => {
        this.email = res.data.email;
        this.name = res.data.name;
        this.surname = res.data.surname;
        this.phone = res.data.phone;
    })
    .catch((res) => {
      if(res.response.status === 401) {
        this.$parent.openSignIn();
      }
    })
  },
  computed: {
    requiredFieldsAreFilled() {
      let fieldsAreFilled;
      if(
        this.name && this.surname  && this.phone && 
        this.amount && this.country && 
        this.city && this.address  
        && this.postCode && this.terms 
      ) {
        fieldsAreFilled = true;
      } else {
        fieldsAreFilled = false;
      }
      return fieldsAreFilled;
    }
  },
  methods: {
    selectCountry(event) {
      this.country = event.text;
      for (let i = 0; i < this.countryOptions.length; i++) {
        if (this.countryOptions[i] == event.text) {
          this.countryId = this.countryOptionsId[i];
        }
      }
    },
    getCounties() {
      this.$http.get(process.env.VUE_APP_API + 'countries')
      .then((res) => {
          this.countryOptions = res.data.payload;
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.$parent.openSignInModal();
        }
      })
    },
    submit() {
      let data = {
        "name": this.name,
        "surname": this.surname,
        "phone": this.phone,
        "amount": this.amount,
        "country": this.country.id,
        "city": this.city,
        "address": this.address,
        "postCode": this.postCode
      }
      this.$emit('topUpSubmit', data)
    },
    nextStep() {
      if (this.amount) {
        this.amountStep = false
      }
      
    },
    goToTerms() {
      this.$parent.closeTopUpModal();
      this.$parent.goToPage('terms');
    },
    goToPrivacy() {
      this.$parent.closeTopUpModal();
      this.$parent.goToPage('privacy');
    }
  }
}
</script>