<template>
  <header class="header">
    <div class="wrapper">
      <div class="left">
        <router-link to="/" class="logo">
          <img src="./../assets/logo.svg" class="img"/>
        </router-link>
      </div>
      <div class="right">
        <div class="search">
          <img class="img" src="./../assets/magnifying-glas.svg" @click="searchSubmit()"/>
          <input type="text" :placeholder="$t('Search')" v-model="search" @keyup.enter="onEnter"/>
        </div>
        <router-link to="/profile/promt-history" class="button link-container dropdown-open" v-if="$parent.isAuth">
          <span>{{ $t('Profile') }}</span>
        </router-link>
        <div class="link-container" v-else>
          <div class="button modal-open" @click="$emit('openSignInModal')">
            <span>{{ $t('Sign In') }}</span>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>

export default {
	name: 'Header',
  props: ['searchedProductText'],
	data: function() {
		return {
      search: ''
		}
	},
  watch: {
    searchedProductText: function(newVal) {
      this.search = newVal;
    }
  },
	methods: {
    openSignInModal() {
      this.$emit('openSignInModal')
    },
    openSignUpModal() {
      this.$emit('openSignUpModal')
    },
    logout() {
      this.$emit('logout')
    },

    searchSubmit() {
      this.$emit('searchSubmit', this.search)
    },
    onEnter() {
      this.searchSubmit();
    }
	}
}
</script>
