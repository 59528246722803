<template>
  <main class="main profile-page page-inside" v-if="$parent.isAuth">
    <div class="main-wrapper">
      <div class="section hero-section">
        <div class="wrapper">
          <div class="profile-form">
            <div class="profile-form-wrapper">
              <div class="wrapper">
                <div class="profile-top">
                  <div class="profile-top-left">
                    <img src="./../assets/user.svg" class="img"/>
                    <div class="text">
                      <div class="title small">{{ $t('name') }} {{ $t('surname') }}</div>
                      <div class="desc">
                        <b>{{ $t('Joined') }}:</b>
                        {{ $parent.formatDate(joined) }}
                      </div>
                    </div>
                    <a class="link desc" @click="activeProfilePage = 'contact-info'"
                       v-if="activeProfilePage !== 'contact-info'">{{ $t('Edite profile') }}</a>
                  </div>
                  <div class="profile-top-right">
                    <img src="./../assets/walletProfile.svg" class="img"/>
                    <div class="text">
                      <div class="title small">{{ $t('My balance') }}</div>
                      <div class="title"><span>{{ balance }} {{ $parent.currency }}</span></div>
                      <button class="button" @click="$parent.openTopUpModal">
                        <span>{{ $t('TOP UP') }}</span>
                      </button>
                    </div>
                  </div>
                </div>
                <ul class="profile-nav pack-list">
                  <template v-for="(item, i) in profileNav">
                    <li :class="['pack-list__item', {'active': item.page === activeProfilePage}]"
                        @click="goToProfilePage(item.page)" :key="i">
                      <div class="desc">{{ $t(item.title) }}</div>
                    </li>
                  </template>
                  <li class='pack-list__item' @click="$parent.logout">
                    <div class="desc">{{ $t('Log out') }}</div>
                  </li>
                </ul>
                <div class="content personal-info" v-if="activeProfilePage === 'contact-info'">
                  <div class="block">
                    <div class="block-top">
                      <div class="title small">{{ $t('Personal Information') }}</div>
                      <div>
                        <div class="input-container">
                          <div class="desc"><span class="red">*</span> {{ $t('First name') }}:</div>
                          <input type="text" :placeholder="$t('First name')" v-model="name"/>
                        </div>
                        <div class="input-container">
                          <div class="desc"><span class="red">*</span> {{ $t('E-mail') }}:</div>
                          <input type="email" :placeholder="$t('E-mail')" v-model="email"/>
                        </div>
                        <div class="input-container">
                          <div class="desc"><span class="red">*</span> {{ $t('Last name') }}:</div>
                          <input type="text" :placeholder="$t('Last name')" v-model="surname"/>
                        </div>
                        <div class="input-container">
                          <div class="desc"><span class="red">*</span> {{ $t('Phone') }}:</div>
                          <input type="tel" :placeholder="$t('Phone')" v-model="phone"/>
                        </div>
                      </div>
                    </div>
                    <button class="button" @click="updateProfileData">
                      <span>{{ $t('UPDATE') }}</span>
                    </button>
                    <transition name="fade">
                      <div class="desc red" v-if="profileUpdateErrorMessaage">{{ $t(profileUpdateErrorMessaage) }}</div>
                    </transition>
                    <transition name="fade">
                      <div class="desc green" v-if="profileUpdateSuccessMessaage">
                        {{ $t(profileUpdateSuccessMessaage) }}
                      </div>
                    </transition>
                  </div>
                  <div class="block">
                    <div class="block-top">
                      <div class="title small">{{ $t('Change Password') }}</div>
                      <div class="input-container">
                        <div class="desc"><span class="red">*</span> {{ $t('Current password') }}:</div>
                        <input type="text" :placeholder="$t('Current password')" v-model="curPass"/>
                      </div>
                      <div class="input-container long">
                        <div class="desc"><span class="red">*</span> {{ $t('New password') }}:</div>
                        <input type="text" :placeholder="$t('New password')" v-model="newPass"/>
                      </div>
                      <div class="input-container long">
                        <div class="desc"><span class="red">*</span> {{ $t('Confirm password') }}:</div>
                        <input type="text" :placeholder="$t('Confirm password')" v-model="repeatPass"/>
                      </div>
                      <div class="pass-rules">
                        <div class="desc bold">{{ $t('Password must contain') }}:</div>
                        <div class="desc">
                          {{ $t('- At least 6 characters, 1 upper case letter (A-Z), 1 lower case letter (a-z), 1 number (0-9)') }}
                        </div>
                      </div>
                    </div>
                    <button class="button" @click="updatePassword">
                      <span>{{ $t('UPDATE') }}</span>
                    </button>
                    <transition name="fade">
                      <div class="desc red" v-if="passUpdateErrorMessaage">{{
                          $t(passUpdateErrorMessaage)
                        }}
                      </div>
                    </transition>
                    <transition name="fade">
                      <div class="desc green" v-if="passUpdateSuccessMessaage">{{ $t(passUpdateSuccessMessaage) }}</div>
                    </transition>
                  </div>
                </div>
                <div class="content profile-table" v-if="activeProfilePage === 'promt-history'">
                  <div class="profile-table-wrapper profile-form-wrapper">
                    <div class="title small">{{ $t('Promt history') }}</div>
                    <div class="table-container">
                      <table>
                        <thead>
                        <tr>
                          <td>{{ $t('Title') }}</td>
                          <td>{{ $t('Prompt') }}</td>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(item,i) in $parent.promtHistory" :key="i">
                          <td>{{ item.title }}</td>
                          <td v-html="item.prompt"></td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div class="content profile-table" v-if="activeProfilePage === 'order-history'">
                  <div class="profile-table-wrapper profile-form-wrapper">
                    <div class="title small">{{ $t('Order history') }}</div>
                    <div class="table-container">
                      <table>
                        <thead>
                        <tr>
                          <td>{{ $t('Date/time') }}</td>
                          <td>{{ $t('Title') }}</td>
                          <td>{{ $t('Status') }}</td>
                          <td>{{ $t('Total') }}</td>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(item,i) in $parent.orderHistory" :key="i">
                          <td>{{ $parent.formatDate(item.created_at) }}</td>
                          <td>{{ item.title }}</td>
                          <td>{{ item.status }}</td>
                          <td>{{ item.sum }} {{ $parent.currency }}</td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div class="content profile-table" v-if="activeProfilePage === 'deposit-history'">
                  <div class="profile-table-wrapper profile-form-wrapper">
                    <div class="title small">{{ $t('Deposit history') }}</div>
                    <div class="table-container">
                      <table>
                        <thead>
                        <tr>
                          <td>{{ $t('Date/time') }}</td>
                          <td>{{ $t('Status') }}</td>
                          <td>{{ $t('Total') }}</td>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(item,i) in $parent.depositHistory" :key="i">
                          <td>{{ $parent.formatDate(item.created_at) }}</td>
                          <td>{{ item.status }}</td>
                          <td>{{ item.sum }} {{ $parent.currency }}</td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>


<script>

export default {
  name: 'Profile',
  props: [],
  components: {},
  data: function () {
    return {
      email: '',
      name: '',
      surname: '',
      phone: '',
      joined: '',
      balance: '',
      profileUpdateSuccessMessaage: '',
      profileUpdateErrorMessaage: '',
      curPass: '',
      newPass: '',
      repeatPass: '',
      passUpdateSuccessMessaage: '',
      passUpdateErrorMessaage: '',
      activeProfilePage: '',
      profileNav: [
        {
          page: 'promt-history',
          title: 'Promt history'
        },
        {
          page: 'contact-info',
          title: 'Contact Information'
        },
        {
          page: 'deposit-history',
          title: 'Deposit history'
        },
        {
          page: 'order-history',
          title: 'Order history'
        }
      ]
    }
  },
  mounted() {
    this.activeProfilePage = this.$route.params.page;
    this.$http.get(process.env.VUE_APP_API + 'user/profile')
        .then((res) => {
          this.email = res.data.email;
          this.name = res.data.name;
          this.surname = res.data.surname;
          this.phone = res.data.phone;
          this.joined = res.data.created_at;
          this.balance = res.data.balance;
        })
        .catch((res) => {
          if (res.response.status === 401) {
            this.$parent.openSignInModal();
          }
        })
  },
  methods: {
    updateProfileData() {
      let profileData = {
        "email": this.email,
        "name": this.name,
        "phone": this.phone,
        "surname": this.surname
      }
      this.$http.put(process.env.VUE_APP_API + 'user/profile', profileData)
          .then((res) => {
            if (res.data.status == "OK") {
              this.profileUpdateSuccessMessaage = 'Success';
              setTimeout(() => {
                this.profileUpdateSuccessMessaage = '';
              }, 3000);
            }
          })
          .catch((res) => {
            if (res.response.status === 401) {
              this.$parent.openSignInModal();
            }
            this.profileUpdateErrorMessaage = res.response.data.message;
            setTimeout(() => {
              this.profileUpdateErrorMessaage = '';
            }, 3000);
          })
    },
    updatePassword() {
      let passwordList = {
        "cur_password": this.curPass,
        "new_password": this.newPass,
        "repeat_password": this.repeatPass
      }
      this.$http.post(process.env.VUE_APP_API + 'user/change-password', passwordList)
          .then((res) => {
            if (res.data.status == "OK") {
              this.passUpdateSuccessMessaage = 'Success';
              this.curPass = '',
                  this.newPass = '',
                  this.repeatPass = '';
              setTimeout(() => {
                this.passUpdateSuccessMessaage = '';
              }, 3000);
            }
          })
          .catch((res) => {
            if (res.response.status === 401) {
              this.$parent.openSignInModal();
            }
            this.passUpdateErrorMessaage = res.response.data.message;
            setTimeout(() => {
              this.passUpdateErrorMessaage = '';
            }, 3000);
          })
    },
    goToProfilePage(page) {
      this.$router.push({path: '/profile/' + page});
      this.activeProfilePage = page;
    }
  }
}
</script>