<template>
  <main class="main product-list-page product-page page-inside">
    <div class="main-wrapper">
      <section class="section hero-section product-list-section">
        <div class="wrapper">
          <transition name="fade">
            <div class="product-preview" v-if="$parent.productPageItem">
              <div class="preview-container">
                <div class="preview-wrapper">
                  <div class="preview">
                    <img :src="$parent.imgDomain + $parent.productPageItem.image_url" class="img"/>
                  </div>
                </div>
              </div>
              <div class="text">
                <div class="text-wrapper">
                  <div class="tag">
                    <span>{{tag}}</span>
                  </div>
                  <div class="title">{{$t($parent.productPageItem.title)}}</div>
                  <ul class="feature-list">
                    <li class="feature-list__item desc">
                      {{$t($parent.productPageItem.prompt_word_count)}} {{$t('words')}}
                    </li>
                    <li class="feature-list__item desc">
                      {{$t('Tested')}}
                      <img src="./../assets/done.svg" class="img"/>
                    </li>
                    <li class="feature-list__item desc">
                      {{$t('Tips')}}
                      <img src="./../assets/done.svg" class="img"/>
                    </li>
                    <li class="feature-list__item desc">
                      {{$t('HQ Images')}}
                      <img src="./../assets/done.svg" class="img"/>
                    </li>
                  </ul>
                  <div class="description">
                    <div class="desc" v-html="$t($parent.productPageItem.description)"></div>
                  </div>
                  <div class="row row-price">
                    <div class="title">{{$t($parent.productPageItem.price)}} <span class="currency">{{currency}}</span></div>
                    <button class="button" @click="getPromt">
                      <span>{{$t('GET PROMPT')}}</span>
                    </button>
                  </div>
                  <div class="spec-text">
                    <div class="desc">
                      {{$t('After purchasing, you will gain access to the prompt file. By purchasing this prompt, you agree to our terms of service.')}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </transition>
        </div>
      </section>
    </div>
  </main>
</template>

<script>
export default {
  name: 'ProductPage',
  props: ['currency', 'productPageItem'],
  components: {
  },
  data: function() {
    return {
      tag: ''
    }
  },
	watch: {
    productPageItem: function(newVal) {
      this.$http.get(process.env.VUE_APP_API + 'categories')
      .then((res) => {
        this.models = res.data.payload.filter(item => item.parent_id === null);
        let newTag = this.models.find(item => item.id === newVal.parent_cat_id)
        newTag = newTag['title']
        this.tag = newTag;
        
      })
      .catch(() => {
          
      })
    },
	},
  created() {
    
  },

  mounted() {
    this.$parent.getProductPageContent(this.$route.params.id);
    
    
  },
  methods: {
    openReadyModal() {
      this.$parent.openReadyModal();
      this.$parent.setReadyPromt();
    },
    getPromt() {
      if (this.$parent.isAuth) {
        this.openReadyModal();
      } else {
        this.$parent.openSignInModal();
      }
    }
  }
}
</script>