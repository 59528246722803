<template>
  <div id="app" :class="['app ' + $route.name]">
    <transition name="fade">
      <div id="page" class="page" v-if="pageLoaded">
        <transition name="fade">
          <Menu v-if="menuVisible"/>
        </transition>
        <div class="mob menu-icon" @click="toggleMenu" :class="{'active': menuVisible}">
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div class='header-container'>
          <Header :searchedProductText="$t(searchedProductText)" @searchSubmit="searchSubmit" @openSignInModal="openSignInModal" @openSignUpModal="openSignUpModal" @logout="logout"/>
        </div>
        <div class='category-bar-container'>
          <CategoryBar @openModelDropdown="openModelDropdown" @setActiveModel="setActiveModel" @setActiveCategory="setActiveCategory"/>
        </div>
        <router-view @formatDate="formatDate"  :currency="$t(currency)"  @goToProduct="goToProduct" @setSort="setSort" @setActiveModel="setActiveModel" @setActiveCategory="setActiveCategory" :activeModels="activeModels" :activeCategories="activeCategories" :productPageItem="productPageItem" :models="models"/>
        <div class='category-bar-container'>
          <CategoryBar :hiddenNav="true" @openModelDropdown="openModelDropdown" @setActiveModel="setActiveModel" @setActiveCategory="setActiveCategory" />
        </div>
        <div class='footer-container'>
          <Footer v-if="isAuth || (!isAuth && $route.name != 'Profile' && $route.name != 'Cart')"/>
        </div>
        <transition name="fade">
          <SignInModal v-if="signInModalIsVisible" @closeSignInModal="closeSignInModal" @openSignUpModal="openSignUpModal" @login="login" @forgotPass="forgotPass"/>
        </transition>
        <transition name="fade">
          <SignUpModal v-if="signUpModalIsVisible" @closeSignUpModal="closeSignUpModal" @openSignInModal="openSignInModal" @registration="registration" />
        </transition>
        <transition name="fade">
          <ContactModal v-if="contactModalIsVisible" @closeContactModal="closeContactModal" @contactSubmit="contactSubmit" />
        </transition>
        <transition name="fade">
          <TopUpModal v-if="topUpModalVisible" @topUpSubmit="topUpSubmit"/>
        </transition>
        <transition name="fade">
          <ReadyModal v-if="readyModalVisible"/>
        </transition>
        <div class="modal modal-overlay" v-if="cookesModalIsVisible">
          <div class="cookies-modal">
            <div class="title">{{ $t('Cookies') }}</div>
            <div class="desc chekbox-label">
              {{ $t('We utilize cookies and similar technologies to optimize the functionality of our website, ensuring a seamless and personalized browsing experience for you. Certain cookies, essential for basic functionality, are set automatically without necessitating explicit consent. In addition to essential cookies, we may present optional cookies and similar technologies that offer various benefits, enhancing your interaction with our website. As these are non-essential, their usage depends on your preferences. For more detailed information, please refer to our') }}
              <a class="title" @click="goToPage('cookie')"> {{ $t('Cookie Policy') }}</a>.
            </div>
            <button class="button" @click="acceptCookies">
              <span>{{ $t('Got it') }}</span>
            </button>
          </div>
        </div>

      </div>
    </transition>
  </div>
</template>


<script>
import Header from './components/Header.vue'
import Menu from './components/Menu.vue'
import Footer from './components/Footer.vue'
import CategoryBar from './components/CategoryBar.vue'
import SignInModal from './components/SignInModal.vue'
import SignUpModal from './components/SignUpModal.vue'
import ContactModal from './components/ContactModal.vue'
import TopUpModal from './components/TopUpModal.vue'
import ReadyModal from './components/ReadyModal.vue'
import {loadScriptsFromString} from "@/utils/load_scripts";
import i18n from "@/i18n";


export default {
  name: 'app',
  components: {
    Header,
    Menu,
    Footer,
    SignInModal,
    SignUpModal,
    ContactModal,
    CategoryBar,
    TopUpModal,
    ReadyModal
  },
  data: function() {
    return {
     cookesModalIsVisible: false, 
     pageLoaded: false,
     menuVisible: false,
     signInModalIsVisible: false,
     signUpModalIsVisible: false,
     contactModalIsVisible: false,
     nav: [
      {
        'title': 'Home',
        'link': '/'
      },
      {
        'title': 'Contact Us',
        'link': '/contact-us'
      },
      {
        'title': 'Faq',
        'link': '/faq'
      }
     ],
      isAuth: false,
      error: '',
      successMessage: '',
      orderHistory: [],
      promtHistory: [],
      depositHistory: [],
      stableDiffusion: [],
      dalle2: [],
      chatGpt: [],
      searchedProductText: '',
      totalSum: '0.00',
      textPageTitle: '',
      textPageHeader: '',
      textPageContent: '',
      productPageItem: '',
      imgDomain: '',
      textPageList: '',
      currency: '',
      hostname: '',
      models: [],
      categories: [],
      categoryOptions: [],
      modelOptions: [],
      packs: [],
      activePackProducts: [],
      activePack: {},
      productList: [],
      sortOptions: [
        {
          id: 'desc',
          text: 'Highest price first'
        }, 
        {
          id: 'asc',
          text:'Lowest price first'
        }
      ],
      sort: '',
      activeCategories: [],
      activeModels: [],
      dropdownCategories: [],
      topUpModalVisible: false,
      readyModalVisible: false,
      readyPromt: false,
      readyPack: false,
      socialLinks: '',
      settings: {}
    }
  },
  watch: {
    $route() {
      window.scrollTo(0, 0);
      this.closeMenu();
      if (this.isAuth) {
        this.getOrderHistory();
        this.getPromtHistory();
        this.getDepositHistory();
      }
    }
  },
  created() {
    let self = this;
    window.addEventListener('click', (e) => {
      const dropdown = document.querySelector(".dropdown");
      const categoryList = document.querySelector(".category-list");
      if (dropdown && categoryList) {
        if (!dropdown.contains(e.target) && !categoryList.contains(e.target)) {
          self.clearDropdownCategories();
        }
      }
    })
  },
  methods: {
    fetchTranslations() {
      this.$http.get(process.env.VUE_APP_API + 'localizations')
          .then((res) => {
            i18n.setLocaleMessage('en', res.data)
            i18n.locale = 'en';
          })
    },
    loadSettings() {
      this.$http.get(process.env.VUE_APP_API + 'settings')
          .then((res) => {
            this.settings = res.data.payload;
            const snippet = res.data.payload.google_tag;

            if (snippet) {
              loadScriptsFromString(snippet);
            }
          })
          .catch((error) => {
            console.error("Error fetching Google Tag Manager snippet:", error);
          });
    },
    cookiesModal() {
      const cookiesAccepted = localStorage.getItem("cookiesAccepted");

      if (!cookiesAccepted) {
        this.cookesModalIsVisible = true;
      }
    },
    declineCookies() {
      this.cookesModalIsVisible = false;
    },
    acceptCookies() {
      localStorage.setItem("cookiesAccepted", "true");
      this.cookesModalIsVisible = false;
    },
    getSocialLinks() {
      this.$http.get(process.env.VUE_APP_API + 'social-links')
      .then(({data}) => {
        this.socialLinks = data.payload;
      })
      .catch((error) => {
        console.log(error);
      });
    },
    buyPack() {
      let data = {
        'pack_id': this.activePack.id
      }
      console.log(data)
      this.$http.post(process.env.VUE_APP_API + 'purchase/pack', data)
      .then((res) => {
        if (res.data.status == "OK") {
          this.successMessage =  'Success';
          this.$router.push({path: '/profile/order-history'});
          

        } else {
          this.error = res.data.message
        }
      })
      .catch((res) => {
        this.error = res.response.data.message
      })
    },
    topUpSubmit(data) {
      this.$http.post(process.env.VUE_APP_API + 'deposit/checkout', data)
      .then((res) => {
        if (res.data.status == "OK") {
          window.location = res.data.redirect_url;
        } else {
          this.error = res.response.data.message
        }
      })
      .catch((res) => {
        this.error = res.response.data.message
        if(res.response.status === 401) {
          this.openSignInModal();
        }
      })
    },
     buyProduct() {
      let data = {
        'prompt_id': this.productPageItem.id
      }
      this.$http.post(process.env.VUE_APP_API + 'purchase/prompt', data)
      .then((res) => {
        if (res.data.status == "OK") {
          this.successMessage =  'Success';
          this.$router.push({path: '/profile/order-history'});
        } else {
          this.error = res.data.message
        }
      })
      .catch((res) => {
        this.error = res.response.data.message
      })
    },
    openReadyModal() {
      this.readyModalVisible = true;
    },
    setReadyPromt() {
      this.readyPromt = true;
    },
    setReadyPack() {
      this.readyPack = true;
    },
    closeReadyModal() {
      this.readyPromt = false;
      this.readyPack = false;
      this.successMessage = '';
      this.error = '';
      this.readyModalVisible = false;
    },
    openTopUpModal() {
      this.topUpModalVisible = true;
    },
    closeTopUpModal() {
      this.topUpModalVisible = false;
    },
    clearDropdownCategories() {
      this.dropdownCategories = [];
    },
    openModelDropdown(model) {
      this.dropdownCategories = this.categories.filter(item => item.parent_id === model.id);
    },
    getDomainName(hostName) {
      return hostName.substring(hostName.lastIndexOf(".", hostName.lastIndexOf(".") - 1) + 1);
    },
    closeMenu() {
      this.menuVisible = false;
      document.body.style.overflow = "auto";
    },
    toggleMenu() {
      this.menuVisible = !this.menuVisible;
      window.scrollTo(0, 0);
      if (this.menuVisible) {
        document.body.style.overflow = "hidden";
      } else {
         document.body.style.overflow = "auto";
      }
    },
    menuHeight() {
      const doc = document.documentElement;
      doc.style.setProperty("--vh", `${window.innerHeight * 0.01}px`);
    },
    openSignInModal() {
      this.clearError();
      this.signUpModalIsVisible = false;
      this.signInModalIsVisible = true;
    },
    openSignUpModal() {
      this.clearError();
      this.signInModalIsVisible = false;
      this.signUpModalIsVisible = true;
    },
    clearError() {
      this.error = '';
    },
    closeSignUpModal() {
      this.signUpModalIsVisible = false;
      this.clearError();
    },
    closeSignInModal() {
      this.signInModalIsVisible = false;
      this.clearError();
    },
    openContactModal() {
      this.contactModalIsVisible = true;
    },
    closeContactModal() {
      this.contactModalIsVisible = false;
      this.clearError();
    },
    login(data) {
      this.$http.post(process.env.VUE_APP_API + 'login', data)
      .then((res) => {
        if (res.data.status == "OK") {
          this.isAuth = true;
          this.closeSignInModal();
          this.getOrderHistory();
          this.getPromtHistory();
          this.getDepositHistory();
        } else {
          this.error = res.data.message
        }
      })
      .catch((res) => {
          this.error = res.response.data.message;
      })
    },
    forgotPass(data) {
      let self = this;
      this.$http.post(process.env.VUE_APP_API + 'user/reset-password', data)
      .then((res) => {
        if (res.data.status == "OK") {
          this.successMessage = 'Success';
          this.error = ''
          setTimeout(()=> {
            self.successMessage = '';
          }, 2000)
        } else {
          this.error = res.response.data.message
        }
      })
      .catch((error) => {
        this.error = error.response.data.message
      })
    },
    contactSubmit(data) {
      let self = this;
      this.$http.post(process.env.VUE_APP_API + 'contact-us', data)
      .then((res) => {
        if (res.data.status == "OK") {
          self.clearError();
          self.successMessage = 'Thank you!'
          self.setTimeout(function() {
            self.successMessage = '';
            self.closeContactModal();
          }, 3000)
        } else {
          this.error = res.data.message
        }
      })
      .catch((res) => {
          this.error = res.response.data.message;
      })
    },
    registration(data) {
      let self = this;
      if (data.password !== data.passConfirm) {
        this.error = 'Password confirmation failed!';
      } else {
        this.$http.post(process.env.VUE_APP_API + 'register', data)
        .then((res) => {
          if (res.data.status == "OK") {
            this.clearError();
            self.isAuth = true;
            self.closeSignUpModal();
          } else {
            this.error = res.data.message
          }
        })
        .catch((res) => {
            this.error = res.response.data.message;
        })
      }
    },
    logout() {
      this.$http.post(process.env.VUE_APP_API + 'logout')
      .then(() => {
        if (this.$route.name != 'Home') {
          this.$router.push({ path: '/' })
        }
        this.isAuth = false;
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.openSignInModal();
        }
      })
    },
    getOrderHistory() {
      this.$http.get(process.env.VUE_APP_API + 'purchase/history')
      .then((res) => {
        this.orderHistory = res.data.payload
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.openSignInModal();
        }
      })
    },
    getPromtHistory() {
      this.$http.get(process.env.VUE_APP_API + 'user/prompts')
      .then((res) => {
        this.promtHistory = res.data.payload
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.openSignInModal();
        }
      })
    },
    getDepositHistory() {
      this.$http.get(process.env.VUE_APP_API + 'deposit/history')
      .then((res) => {
        this.depositHistory = res.data.payload
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.openSignInModal();
        }
      })
    },
    formatDate(num) {
      let objectDate = new Date(num * 1000);
      let day = objectDate.getDate();
      if (day < 10) {
          day = '0' + day;
      }
      let month = objectDate.getMonth() + 1;
      if (month < 10) {
          month = `0${month}`;
      }
      let year = objectDate.getFullYear();

      function padTo2Digits(x) {
        return String(x).padStart(2, '0');
      }
      let hoursAndMinutes = padTo2Digits(objectDate.getHours()) + ':' + padTo2Digits(objectDate.getMinutes());
      let format = day + "." + month + "." + year + ' ' + hoursAndMinutes;

      return format
    },
    getStableDiffusion() {
      this.$http.get(process.env.VUE_APP_API + 'products/top-category?category_id=10&limit=8')
      .then((res) => {
        this.stableDiffusion = res.data.payload;
      })
      .catch(() => {
        
      })
    },
    getDalle2() {
      this.$http.get(process.env.VUE_APP_API + 'products/top-category?category_id=9&limit=8')
      .then((res) => {
        this.dalle2 = res.data.payload;
      })
      .catch(() => {
        
      })
    },
    getChatGpt() {
      this.$http.get(process.env.VUE_APP_API + 'products/top-category?category_id=8&limit=8')
      .then((res) => {
        this.chatGpt = res.data.payload;
      })
      .catch(() => {
        
      })
    },
    clearSearch() {
      this.searchedProductText = '';
    },
    clearModel() {
      this.activeModels = [];
    },
    clearCategory() {
      this.activeCategories = [];
    },
    goToProduct(item) {
      this.$router.push({path: '/products/' + item.id, params: {data: item.id}});
      this.getProductPageContent(item.id);
    },
    getProductPageContent(id) {
      this.productPageItem = '';
      this.$http.get(process.env.VUE_APP_API + 'products/' + id + '/info')
      .then((res) => {
        this.productPageItem = res.data.payload;
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.openSignInModal();
        }
      })
    },
    goToPage(item) {
      let newItem;
      if (item == 'privacy') {
        for (let i = 0; i < this.textPageList.length; i++) {
          if (this.textPageList[i].title.toLowerCase().includes('privacy')) {
            newItem = this.textPageList[i].id;
          } 
        }
      } else if (item == 'terms') {
        for (let i = 0; i < this.textPageList.length; i++) {
          if (this.textPageList[i].title.toLowerCase().includes('terms')) {
            newItem = this.textPageList[i].id;
          } 
        }
      } else if (item == 'cookie') {
        for (let i = 0; i < this.textPageList.length; i++) {
          if (this.textPageList[i].title.toLowerCase().includes('cookie')) {
            newItem = this.textPageList[i].id;
          } 
        }
      } else {
        newItem = item
      }
      this.$router.push({path: '/pages/' + newItem, params: {data: newItem}})
      this.getTextPageContent(newItem);
      this.closeSignUpModal();
    },
    getTextPageContent(id) {
      this.textPageTitle = '';
      this.textPageHeader = '';
      this.textPageContent = '';
      this.$http.get(process.env.VUE_APP_API + 'static-pages/' + id)
      .then((res) => {
        this.textPageTitle = res.data.payload.title;
        this.textPageContent = res.data.payload.content;
        this.textPageHeader = res.data.payload.header;
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.openSignInModal();
        }
      })
    },
    getTextPages() {
      this.$http.get(process.env.VUE_APP_API + 'static-pages')
      .then((res) => {
        this.textPageList = res.data.payload
      })
      .catch(() => {
         
      })
    },
    getCategories() {
      this.$http.get(process.env.VUE_APP_API + 'categories')
      .then((res) => {
        this.categories = res.data.payload.filter(item => item.parent_id !== null);
        const newCategories = this.categories.map(({
          id,
          title: name,
          parent_id
        }) => ({
          id,
          name,
          parent_id
        }));
        // newCategories.unshift({name: 'All'});
        const filteredArray = this.removeIdenticalObjects(newCategories);
        this.categoryOptions = filteredArray;
        this.activeCategoies = [];
      })
      .catch(() => {
         
      })
    },
    removeIdenticalObjects(arr) {
      return arr.filter((item, index) => {
        const firstIndex = arr.findIndex((el) => el.name === item.name);
        return index === firstIndex;
      });
    },
    getModels() {
      this.$http.get(process.env.VUE_APP_API + 'categories')
      .then((res) => {
        this.models = res.data.payload.filter(item => item.parent_id === null);
        const newCategories = this.models.map(({
          id,
          title: name,
        }) => ({
          id,
          name
        }));
        // newCategories.unshift({name: 'All'});
        this.modelOptions = newCategories;
        this.activeModels = [];

      })
      .catch(() => {
         
      })
    },
    getPacks() {
      this.$http.get(process.env.VUE_APP_API + 'packs')
      .then((res) => {
        this.packs = res.data.payload;
        this.activePack = this.packs[0];
        this.getActivePackProducts(this.activePack)
      })
      .catch(() => {
         
      })
    },
    getActivePackProducts(item) {
      this.$http.get(process.env.VUE_APP_API + 'packs/' + item.id)
      .then((res) => {
        let self = this;
        self.activePack = item;
        self.activePackProducts = '';
        setTimeout(function() {
          self.activePackProducts = res.data.payload;
        }, 300)
        
      })
      .catch(() => {
         
      })
    },
    searchSubmit(text) {
      this.searchedProductText = text;   
      this.setAllModels();
      this.openProducts();
    }, 
    setAllModels() {
      const newCategories = this.models.map(({
        id,
        title: name,
      }) => ({
        id,
        name
      }));
      this.setActiveModel(newCategories);
    },
    filterArrayBySearchField(array, searchField) {
      const filteredArray = array.filter((item) =>
        item.title.toLowerCase().includes(searchField.toLowerCase())
      );
      return filteredArray;
    },
    openProducts() {
      let self = this;
      let newActiveModels;
      if (!Array.isArray(self.activeModels) ) {
        newActiveModels = self.activeModels.id
      } else {
        newActiveModels = self.activeModels.map(obj => obj.id);
      }
      let modelIdApi = newActiveModels;

      let newActiveCategories;
      if (!Array.isArray(self.activeCategories) ) {
        newActiveCategories = self.activeCategories.id
      } else {
        newActiveCategories = self.activeCategories.map(obj => obj.id);
      }
      let categoryIdApi = '&category_id=' + newActiveCategories;
      let sortApi = '&sort=' + self.sort
      
      this.$http.get(process.env.VUE_APP_API + 'products/catalog?model_id=' + modelIdApi + categoryIdApi + sortApi )
      .then((res) => {
          self.productList = res.data.payload;
          let productList = this.filterArrayBySearchField(res.data.payload, this.searchedProductText);
          self.productList = productList;
          if (self.$route.name != 'ProductList') {
            self.$router.push({path: '/product-list'});
          }
      })
      .catch(() => {
        
      })
    },
    setSort(sort) {
      this.sort = sort;
      this.openProducts();
    },
    setActiveModel(models) {
      this.activeModels = models;
      if (!Array.isArray(models) ) {
        let activeCategories = this.categories.filter(item => item.parent_id === models.id);
        let mapped = activeCategories.map(({
          id,
          title: name,
        }) => ({
          id,
          name
        }));
        this.activeCategories = mapped;
      } else {
        const filteredArray = this.filterObjectsWithSameId2(this.categories, models);
        
        let mapped = filteredArray.map(({
          id,
          title: name,
        }) => ({
          id,
          name
        }));
        this.activeCategories = mapped;
      }
      this.openProducts();
    },
    setActiveCategory(categories) {
      this.activeCategories = categories;
      
      if (!Array.isArray(categories) ) {
        let activeModel = this.models.filter(item => item.id === categories.parent_id);
        let mapped = activeModel.map(({
          id,
          title: name,
        }) => ({
          id,
          name
        }));
        this.activeModels = mapped;
      } else {
        const filteredArray = this.filterObjectsWithSameId(this.models, categories);
        
        let mapped = filteredArray.map(({
          id,
          title: name,
        }) => ({
          id,
          name
        }));
        this.activeModels = mapped;
      }
      this.openProducts();
    },
    filterObjectsWithSameId(arr1, arr2) {
      const resultArray = arr1.filter(obj1 =>
        arr2.some(obj2 => obj2.parent_id === obj1.id)
      );
      return resultArray;
    },
    filterObjectsWithSameId2(arr1, arr2) {
      const resultArray = arr1.filter(obj1 =>
        arr2.some(obj2 => obj2.id === obj1.parent_id)
      );
      return resultArray;
    },
    isElementVisible(element) {
      const computedStyle = window.getComputedStyle(element);
      const isVisible = 
        computedStyle.display !== 'none' &&
        computedStyle.visibility !== 'hidden' &&
        parseInt(computedStyle.width, 10) > 0 &&
        parseInt(computedStyle.height, 10) > 0;

      return isVisible;
    }
  },
  mounted: function() {
    this.fetchTranslations();
    this.loadSettings();
    this.cookiesModal();
    this.getSocialLinks();
    this.hostname = this.getDomainName(window.location.hostname)
    this.currency = process.env.VUE_APP_CURRENCY;
    this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;
    this.pageLoaded = true;
    let self = this;
    window.addEventListener("resize", self.menuHeight);
    self.menuHeight();
    
    self.$http.get(process.env.VUE_APP_API + 'is-auth')
    .then((res) => {
        console.log('auth: ' + res.data.authenticated);
        self.isAuth = res.data.authenticated

        if (res.data.authenticated) {
          self.getOrderHistory();
          self.getPromtHistory();
          self.getDepositHistory();
        }
    })
    .catch((res) => {
        if(res.response.status === 401) {
          this.openSignInModal();
        }
    })

    this.sort = this.sortOptions[0].id;
    this.getStableDiffusion();
    this.getDalle2();
    this.getChatGpt();
    this.getTextPages();
    this.getModels();
    this.getCategories();
    this.getPacks();
  },
  computed: {
    
  }
}
</script>
