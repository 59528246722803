<template>
  <main class="main page-inside text-page faq-page">
    <div class="main-wrapper">
      <div class="section hero-section">
        <transition name="fade">
          <div class="wrapper">
            <div class="title">{{$t('Frequently Asked Questions')}}</div>
            <div class="title small">1. {{$t('What is PromtGeneral.io?')}}</div>
            <div class="desc">{{$t('PromtGeneral.io is an innovative platform that harnesses the power of advanced AI models like DALL E2 and ChatGPT to assist users in generating creative prompts and content. Whether you\'re a writer, designer, or simply seeking inspiration, PromtGeneral.io offers a range of services to ignite your imagination.')}}</div>
            <div class="title small">2. {{$t('How does the content generation process work?')}}</div>
            <div class="desc">{{$t('To generate content, simply enter your prompt or question into the input field and let our AI models work their magic. They will analyze your input and generate a creative response, be it text or images. You can customize various parameters to shape the output according to your preferences.')}}</div>
            <div class="title small">3. {{$t('What is Stable Diffusion?')}}</div>
            <div class="desc">{{$t('Stable Diffusion is a groundbreaking technique utilized by PromtGeneral.io. It enhances the coherence and quality of the generated content, resulting in more refined and realistic outputs. Stable Diffusion ensures that the AI-generated content aligns better with the context and intent of the prompts.')}}</div>
            <div class="title small">4. {{$t('Can I purchase individual prompts?')}}</div>
            <div class="desc">{{$t('Yes, you can! We offer a Prompt Marketplace where you can browse and purchase individual prompts across various genres. Simply choose the prompts that resonate with you and add them to your cart for a one-time purchase.')}}</div>
            <div class="title small">5. {{$t('Are there any bundled options available?')}}</div>
            <div class="desc">{{$t('Absolutely! We understand that some users may prefer a curated collection of prompts. That\'s why we also offer packs of prompts, such as Genre Packs, Writing Style Packs, Story Setting Packs, and more. These packs provide a variety of prompts at a bundled price, offering greater value for your creative journey.')}}</div>
            <div class="title small">6. {{$t('How much does it cost to generate content?')}}</div>
            <div class="desc">{{$t('The cost for generating content using our AI models is 0.1 euros per generation. You can create multiple iterations and explore different options to refine your ideas without breaking the bank.')}}</div>
            <div class="title small">7. {{$t('What payment methods do you accept?')}}</div>
            <div class="desc">{{$t('We currently accept payments exclusively through major credit and debit cards. Our payment gateway ensures a secure and seamless transaction process, providing you with a convenient and hassle-free experience. Rest assured that your payment information is handled with the utmost confidentiality and security.')}}</div>
            <div class="title small">8. {{$t('How do I manage my purchases and generated content?')}}</div>
            <div class="desc">{{$t('Upon creating an account on PromtGeneral.io, you gain access to a personalized dashboard. From there, you can manage your purchased prompts, track your activity, and access your generated content. The dashboard allows you to organize and retrieve your creative assets easily.')}}</div>
            <div class="title small">9. {{$t('What support options are available?')}}</div>
            <div class="desc">
              {{$t('We provide comprehensive support to assist you in navigating the platform. You can visit our Support and Contact section to access FAQs, user guides, and helpful resources. If you have specific inquiries or need personalized assistance, you can reach out to our dedicated support team through email or our support ticket system.')}}<br/>
              {{$t('If you have any further questions or need additional assistance, please don\'t hesitate to contact us. We\'re here to ensure you have the best experience on PromtGeneral.io!')}}
            </div>
          </div>
        </transition>
      </div>
    </div>
  </main>
</template>
<script>

export default {
  name: 'Faq',
  props: [],
  components: {
  },
  data: function() {
    return {
      
    }
  },
  mounted() {
  },
  methods: {
    
  }
}
</script>