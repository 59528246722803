<template>
	<div class="category-bar">
    <div class="wrapper">
      <ul class="category-list">
          <template  v-for="(item, i) in $parent.models">
            <li class="item" :key="i" v-if="!hiddenNav">
              <a @click="$emit('openModelDropdown', item)" class="desc">{{item.title}}</a>
              <div class="dropdown" v-if="$parent.dropdownCategories.length && item.id === $parent.dropdownCategories[0].parent_id">
                <ul class="dropdown-category-list">
                  <template v-for="(item, i) in $parent.dropdownCategories">
                    <li class="item" :key="i">
                      <a @click="onSelectCategory(item)" class="desc">{{item.title}}</a>
                    </li>
                  </template>
                </ul>
              </div>
            </li>
          </template>
        </ul>
    </div>
  </div>
</template>

<script>

export default {
	name: 'CategoryBar',
  props: ['hiddenNav'],
	data: function() {
		return {
		}
	},
	methods: {
    onSelectModel(item) {
      this.$emit('setActiveModel', item)
    },
    onSelectCategory(item) {
      this.$parent.clearDropdownCategories();
      this.$emit('setActiveCategory', item)
    },
    
	}
}
</script>
