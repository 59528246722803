<template>
  <div class="modal sign-modal">
    <div class="overlay"  @click="$emit('closeSignInModal')"></div>
    <div class="wrapper">
      <img class="close" @click="$emit('closeSignInModal')" src="./../assets/close.svg"/>
      <div class="container">
        <div class="form">
          <div class="form-wrapper">
            <div class="left">
              <div class="title">
                {{ $t('Create an account') }} <br/><span>{{ $t('to unlock additional features and save your favorite prompts and generated content') }}</span>
              </div>
              <div class="modal-bottom">
                <div class="desc">{{ $t("Don't have account?") }}</div>
                <div class="link switch-register" @click="$emit('openSignUpModal')">{{ $t('Please register') }}</div>
              </div>
            </div>
            <div :class="['form-fields login-content', {'hidden': !loginContentVisible}]">
              <div class="form-fields-wrapper">
                <div class="title">{{ $t('Login') }}</div>
                <label>
                  <div class="desc">{{ $t('E-mail:') }}</div>
                  <input type="email" :placeholder="$t('Email')" v-model="email"/>
                </label>
                <label>
                  <div class="desc">{{ $t('Password:') }}</div>
                  <input type="password" :placeholder="$t('Password')" v-model="pass"/>
                </label>
                <div class="cta-container">
                  <div class="link" @click="switchToforgotPass">{{ $t('Forgot password?') }}</div>
                  <button class="button" @click="submitLogin">
                    <span>{{ $t('SIGN IN') }}</span>
                  </button>
                </div>
                <transition name="slide">
                  <div v-if="$parent.error" class="error-desc desc red">{{ $t($parent.error) }}</div>
                </transition>
              </div>
            </div>
            <div :class="['form-fields forgot-pass-content', {'hidden': !forgotPassContentVisible}]">
              <div class="form-fields-wrapper">
                <div class="title">{{ $t('Forgot password?') }}</div>
                <label>
                  <div class="desc">{{ $t('E-mail:') }}</div>
                  <input type="email" :placeholder="$t('Email')" v-model="email"/>
                </label>
                <div class="cta-container">
                  <button class="button" @click="submitForgotPass">
                    <span>{{ $t('Recover') }}</span>
                  </button>
                </div>
                <div class="modal-bottom">
                  <div class="desc">{{ $t('Have account?') }}</div>
                  <div class="link switch-login" @click="switchToLogin()">{{ $t('Login') }}</div>
                </div>
                <transition name="fade">
                  <div v-if="$parent.successMessage" class="desc green">{{ $t($parent.successMessage) }}</div>
                </transition>
                <transition name="slide">
                  <div v-if="$parent.error" class="error-desc desc red">{{ $t($parent.error) }}</div>
                </transition>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>

export default {
  name: 'SignInModal',
  props: [],
  components: {
  },
  data: function() {
    return {
      loginContentVisible: true,
      forgotPassContentVisible: false,
      email: '',
      pass: ''
    }
  },
  mounted() {
    
  },
  methods: {
    closeSignInModal() {
      this.$emit('closeSignInModal')
    },
    switchToforgotPass() {
      this.$parent.clearError();
      setTimeout(()=> {
        this.loginContentVisible = false;
        this.forgotPassContentVisible = true;
      }, 100)
    },
    switchToLogin() {
      this.$parent.clearError();
      setTimeout(()=> {
        this.forgotPassContentVisible = false;
        this.loginContentVisible = true;
      }, 100)
    },
    submitLogin() {
      let loginData = {
        "username": this.email,
        "password": this.pass
      }
      this.$emit('login', loginData)
    },
    submitForgotPass() {
      let data = {
        "email": this.email
      }
      this.$emit('forgotPass', data)
    },
    openSignUpModal() {
      this.$emit('openSignUpModal')
    }
  }
}
</script>