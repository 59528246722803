<template>
  <main class="main home-page">
    <div class="main-wrapper">
      <div class="section hero-section">
        <div class="wrapper">
          <div class="left">
            <div class="title big">{{ $t('Welcome') }} <br/> <span> {{ $t('to PromtGeneral.io') }},</span></div>
            <div class="desc">
              {{
                $t('where creativity meets AI! Unlock and explore the power of advanced AI models to get captivating prompts.')
              }}
            </div>
            <div class="desc">
              {{
                $t('Discover a vast collection of prompts for various genres, generate content using cutting-edge AI models like DALL·E2 and ChatGPT, and enhance the quality of your creations with the innovative Stable Diffusion technique.')
              }}
            </div>
            <div class="button-container">
              <a @click="setStableDiffusionModel" class="button">
                <span>{{ $t('GET STARTED NOW') }}</span>
              </a>
              <div class="desc">{{ $t('and embark on a journey of limitless inspiration!') }}</div>
            </div>
          </div>
          <div class="right">
            <img src="./../assets/hero.png" class="img"/>
          </div>
        </div>
      </div>
      <div class="section arrivals-section">
        <div class="top">
          <div class="title">
            {{ $t('Stable Diffusion') }}
          </div>
          <a @click="setStableDiffusionModel" class="link">
            <span>{{ $t('EXPLORE ALL') }}</span>
          </a>
        </div>
        <div class="wrapper">
          <SlickItem v-if="$parent.stableDiffusion.length" :list="$parent.stableDiffusion" :tag="$t('Stable Diffusion')"
                     @goToProduct="goToProduct" :currency="currency"/>
        </div>
      </div>
      <div class="section explore-and-purchase-section">
        <div class="wrapper">
          <div class="title big">
            {{ $t('Explore and Purchase') }}<br/> <span>{{ $t('Engaging Prompts') }}</span>
          </div>
          <div class="row">
            <img src="./../assets/explore.jpg" class="img"/>
            <div class="text">
              <div class="desc">{{
                  $t('Filters by genres and themes: Fiction, Science Fiction, Fantasy, Romance, Mystery, Poetry, Technical Writing, etc.')
                }}
              </div>
            </div>
          </div>
          <div class="row">
            <div class="text">
              <div class="desc">{{
                  $t('Spark your imagination with our captivating science fiction prompts, filled with futuristic worlds and thrilling adventures')
                }}
              </div>
            </div>
            <img src="./../assets/purchase.jpg" class="img"/>
          </div>
        </div>
      </div>
      <div class="section pack-section">
        <div class="wrapper">
          <div class="title medium">
            {{ $t('Choose the prompts that resonate with you and take your creativity to new heights.') }}
          </div>
          <ul class="pack-list">
            <template v-for="(item, i) in $parent.packs">
              <li :class="['pack-list__item', {'active': item.id === $parent.activePack.id}]"
                  @click="$parent.getActivePackProducts(item)" :key="i">
                <div class="desc">{{ $t(item.title) }}</div>
              </li>
            </template>
          </ul>
          <SlickItem @openSignInModal="$parent.openSignInModal" :isAuth="$parent.isAuth"
                     :activePack="$parent.activePack" @openReadyModal="openReadyModal"
                     v-if="$parent.activePackProducts.length" :list="$parent.activePackProducts"
                     :tag="$t($parent.activePack.title)" @goToProduct="goToProduct" :currency="currency"
                     :isPack="true"/>
        </div>
      </div>
      <div class="section arrivals-section dalle2-section">
        <div class="top">
          <div class="title">
            {{ $t('DALLE-2') }}
          </div>
          <a @click="setDalle2Model" class="link">
            <span>{{ $t('EXPLORE ALL') }}</span>
          </a>
        </div>
        <div class="wrapper">
          <SlickItem v-if="$parent.dalle2.length" :list="$parent.dalle2" :tag="$t('DALLE-2')" @goToProduct="goToProduct"
                     :currency="currency"/>
        </div>
      </div>
      <div class="section secure-section">
        <div class="wrapper">
          <div class="secure-section-content">
            <img src="./../assets/wallet.svg" class="img"/>
            <div class="title medium">{{ $t('Secure and Convenient Payments') }}</div>
            <div class="desc">{{
                $t('We prioritize the security of your payment information. Enjoy a safe and hassle-free checkout experience. Pay using major credit/debit cards.')
              }}
            </div>
          </div>
        </div>
      </div>
      <div class="section arrivals-section chatGpt-section">
        <div class="top">
          <div class="title">
            {{ $t('ChatGPT') }}
          </div>
          <a @click="setChatGptModel" class="link">
            <span>{{ $t('EXPLORE ALL') }}</span>
          </a>
        </div>
        <div class="wrapper">
          <SlickItem v-if="$parent.chatGpt.length" :list="$parent.chatGpt" :tag="$t('ChatGPT')"
                     @goToProduct="goToProduct" :currency="currency"/>
        </div>
      </div>
      <div class="section help-section">
        <div class="wrapper">
          <div class="title medium">{{ $t('We\'re Here to Help') }}</div>
          <div class="help-list">
            <div class="help-list__item">
              <img src="./../assets/setting.svg" class="img"/>
              <div class="desc">{{
                  $t('Find answers to common questions and explore user guides to make the most out of the website\'s features.')
                }}
              </div>
              <router-link to="/faq" class="button">
                <span>{{ $t('FAQ') }}</span>
              </router-link>
            </div>
            <div class="help-list__item">
              <img src="./../assets/customer-support.svg" class="img"/>
              <div class="desc">{{
                  $t('Reach out to our dedicated support team via email or our support ticket system for any inquiries or assistance.')
                }}
              </div>
              <button @click="$parent.openContactModal" class="button">
                <span>{{ $t('SUPPORT') }}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import SlickItem from '../components/SlickItem.vue';


export default {
  name: 'Home',
  props: ['currency'],
  components: {
    SlickItem
  },
  data: function () {
    return {
      imgDomain: '',
    }
  },
  mounted() {
    this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;
  },
  methods: {
    goToProduct(item) {
      this.$emit('goToProduct', item)
    },
    setChatGptModel() {
      let model = this.$parent.models.find((obj) => obj.id === 8)
      this.$emit('setActiveModel', model);
    },
    setDalle2Model() {
      let model = this.$parent.models.find((obj) => obj.id === 9)
      this.$emit('setActiveModel', model);
    },
    setStableDiffusionModel() {
      let model = this.$parent.models.find((obj) => obj.id === 10)
      this.$emit('setActiveModel', model);
    },
    openReadyModal() {
      this.$parent.openReadyModal();
      this.$parent.setReadyPack();
    }
  }
}
</script>