<template>
	<footer class="footer">
      <div class="footer-section">
        <div class="wrapper">
          <div class="left">
            <div class="desc">{{ $parent.settings.requisites }}</div>
            <div class="desc">{{ $t('The provided prompts are intended for use with specific neural networks or creative platforms. To fully utilize these prompts, users are required to have accounts on the respective platforms, where they can explore their artistic potential with the help of advanced AI-driven tools.') }}</div>
            <div class="desc">{{ $t('support') }}: {{ $parent.settings.support_email }}</div>
          </div>
          <div class="right">
            <div class="top">
              <ul class="nav">
                <template  v-for="(item, i) in $parent.nav">
                  <li class="nav__item" :key="i">
                    <a @click="$parent.openContactModal" v-if="item.link === '/contact-us'">
                      {{item.title}}
                    </a>
                    <router-link :to="item.link" v-if="item.link !== '/contact-us'">
                      {{item.title}}
                    </router-link>
                  </li>
                </template>
              </ul>
              <ul class="nav" v-if="$parent.textPageList.length">
                <template  v-for="item in $parent.textPageList">
                  <li class="nav__item nav__item-text-pages" :key="item.id">
                    <a @click="$parent.goToPage(item.id)">
                      {{item.title}}
                    </a>
                  </li>
                </template>
              </ul>
            </div>
            <div class="bottom">
              <ul class="socials">
                <li v-for="link in $parent.socialLinks" :key="link.image" class="nav__item nav__item-text-pages">
                  <a :href="link.url" class="desc" target="_blank">
                    <img :src="$parent.imgDomain + link.image" :alt="link.url" class="img"/>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="footer-section last">
          <div class="wrapper">
            <div class="left">
              <div class="desc">© {{ new Date().getFullYear() }} {{ $parent.settings.copyright }}</div>
            </div>
            <div class="right">
              <ul class="list">
                <li class="item">
                  <img src="./../assets/mc_symbol.svg" class="img"/>
                </li>
                <li class="item">
                  <img src="./../assets/visa.svg" class="img"/>
                </li>
                <li class="item">
                  <img src="./../assets/3ds.png" class="img"/>
                </li>
                <li class="item">
                  <img src="./../assets/multibanco.svg" class="img"/>
                </li>
                <li class="item">
                  <img src="./../assets/mbway.svg" class="img"/>
                </li>
              </ul>
            </div>
          </div>
      </div>
    </footer>
</template>

<script>

export default {
	name: 'Footer',
	props: [],
	data: function() {
		return {
		}
	},
	methods: {
		
	},
	mounted() {
    
  }
}
</script>
