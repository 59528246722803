<template>
  <main class="main product-list-page page-inside">
    <div class="main-wrapper">
      <section class="section hero-section product-list-section">
        <div class="wrapper">
          <div class="top">
            <div class="filters">
              <div class="filters-wrapper">
                <div class="left">
                  <div class="select-container" v-if="$parent.sortOptions.length">
                    <Select2 class="select2" v-model="sort"
                             :options="$parent.sortOptions"
                             :placeholder="$t('Highest price first')"
                             @select="selectSort($event)"
                    />
                  </div>
                  <div class="select-container" v-if="$parent.modelOptions.length">
                    <multiselect
                        v-model="model"
                        :options="$parent.modelOptions"
                        :multiple="true"
                        :placeholder="$t('Model')"
                        label="name"
                        track-by="name"
                        @select="onSelectModel"
                        @remove="onSelectModel"
                        ref="multiselect1"
                        v-click-outside="handleClose1"
                        @open="handleClose2"
                    ></multiselect>
                  </div>
                  <div class="select-container" v-if="$parent.categoryOptions.length">
                    <multiselect
                        v-model="category"
                        :options="$parent.categoryOptions"
                        :multiple="true"
                        :placeholder="$t('Category')"
                        label="name"
                        track-by="name"
                        @select="onSelectCategory"
                        @remove="onSelectCategory"
                        ref="multiselect2"
                        v-click-outside="handleClose2"
                        @open="handleClose1"
                    ></multiselect>
                  </div>
                </div>
                <div class="select-container select-container-reset">
                  <div class="reset" @click="resetFilters">
                    <img src="./../assets/refreshing.svg" class="img"/>
                    <div class="desc">{{ $t('Reset all filters') }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <transition name="fade">
            <div class="list products" v-if="$parent.productList">
              <div class="item" v-for="(item, i) in $parent.productList" :key="i">
                <ProductCard :models="$parent.models" :tag="item.parent_cat_id" :item="item" :sliderIsClickble="true" @goToProduct="goToProduct" :currency="currency"/>
              </div>
              <div class="title no-products" v-if="!$parent.productList.length">
                {{ $t('No products!') }}
              </div>
            </div>

          </transition>
        </div>
      </section>
    </div>
  </main>
</template>

<script>
import Select2 from 'vue3-select2-component';
import ProductCard from '../components/ProductCard.vue';
import Multiselect from 'vue-multiselect'
import Vue from 'vue'
Vue.component('multiselect', Multiselect)
Vue.directive('click-outside', {
  bind(el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      // Check if the clicked element is outside the dropdown
      if (!(el === event.target || el.contains(event.target))) {
        // Call the provided method to close the dropdown
        vnode.context[binding.expression]();
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent);
  },
  unbind(el) {
    document.body.removeEventListener('click', el.clickOutsideEvent);
  },
});

export default {
  name: 'ProductList',
  props: ['currency', 'activeModels', 'activeCategories', 'models'],
  components: {
    Select2,
    ProductCard,
    Multiselect
  },
  data: function() {
    return {
      imgDomain: '',
      sort: '',
      category: [],
      model: [],
      categoryOptions: {},
      modelOptions: {},
      productList: {}
    }
  },
  watch: {
    activeModels: function(newVal) {
      if (Array.isArray(newVal)) {
        this.model = newVal;
      } else {
        let newArray = [];
        newArray.push(newVal);
        let mapped = newArray.map(({
          id,
          title: name,
        }) => ({
          id,
          name
        }));
        this.model = mapped;
      }
    },
    activeCategories: function(newVal) {
       if (Array.isArray(newVal)) {
        this.category = newVal;
      } else {
        let newArray = [];
        newArray.push(newVal);
        let mapped = newArray.map(({
          id,
          title: name,
          parent_id
        }) => ({
          id,
          name,
          parent_id
        }));
        this.category = mapped;
      }
    }
  },
  mounted() {
    this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;
    this.sort = this.$parent.sortOptions[0].id;

    if (this.$parent.activeCategories) {
      if (Array.isArray(this.$parent.activeCategories)) {
        this.category = this.$parent.activeCategories;
      } else {
        let newArray = [];
        newArray.push(this.$parent.activeCategories);
        let mapped = newArray.map(({
          id,
          title: name,
          parent_id
        }) => ({
          id,
          name,
          parent_id
        }));
        this.category = mapped;
      }
    }

    if (this.$parent.activeModels) {
      if (Array.isArray(this.$parent.activeModels)) {
        this.model = this.$parent.activeModels;
      } else {
        let newArray = [];
        newArray.push(this.$parent.activeModels);
        let mapped = newArray.map(({
          id,
          title: name,
        }) => ({
          id,
          name
        }));
        this.model = mapped;
      }
    }
    
  },
  methods: {
    handleClose1() {
      this.$refs.multiselect1.deactivate();
    },
    handleClose2() {
      this.$refs.multiselect2.deactivate();
    },
    onSelectModel() {
      this.$emit('setActiveModel', this.model)
    },
    onSelectCategory() {
      this.$emit('setActiveCategory', this.category)
    },
    selectSort(event) {
      this.sort = event.id;
      this.$emit('setSort', this.sort)
    },
    resetFilters() {
      this.$parent.setAllModels();
      this.$parent.clearSearch();
      this.$parent.openProducts();
    },
    goToProduct(item) {
      this.$emit('goToProduct', item)
    }
  }
}
</script>